import user from '@/mixins/user'
import { waterTestRef } from '@/firebase/db'
export default {
  mixins: [user],
  methods: {
    async getWaterTestHistory(parcelId) {
      let res = await waterTestRef.doc(parcelId).get()
      if (res.exists) {
        return res.data()
      } else {
        return null
      }
    },
    async saveWaterTestHistory(parcelId, data) {
      let source = this.$store.getters.waterSource
      await waterTestRef.doc(parcelId).set({ source: source, data: data })
    },
    async saveWaterData(id, data) {
      await waterTestRef.doc(id).set(data)
    }
  }
}
