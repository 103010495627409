<template>
  <base-form @submit="addWaterHistory" @cancel="$emit('cancel')">
    <template v-slot:button>Save <v-icon>mdi-content-save</v-icon></template>
  </base-form>
</template>

<script>
import parcel from '@/mixins/parcel'
export default {
  mixins: [parcel],
  components: {
    BaseForm: () => import('@/components/Water/BaseForm')
  },
  methods: {
    addWaterHistory(e) {
      this.$store.commit('addWaterTestHistory', { ...e.formData })
      e.resetForm()
      this.$emit('updated')

      this.updateMetrics({ feature: 'water', action: 'create' })
    }
  }
}
</script>

<style></style>
