<template>
  <div>
    <v-dialog v-model="description.dialog" max-width="900">
      <nitrate-detail
        :showHide="true"
        :value="description.data"
        @close="description.dialog = false"
      ></nitrate-detail>
    </v-dialog>
    <v-card elevation="3" hover class="mx-auto card">
      <v-container>
        <base-chart
          v-model="waterTestHistory"
          @clickBar="handleClick"
        ></base-chart>
      </v-container>
    </v-card>
    <p class="text-center mt-2 primary--text">
      Click on any bar above to learn more about the health risks of that level
      of nitrate in your water.
    </p>
  </div>
</template>

<script>
import utils from '@/mixins/utils'
export default {
  mixins: [utils],
  components: {
    BaseChart: () => import('@/components/Water/BaseChart'),
    NitrateDetail: () => import('@/components/Water/NitrateDetail')
  },
  data: () => ({
    description: {
      dialog: false,
      data: {}
    }
  }),
  computed: {
    waterTestHistory() {
      return [...this.$store.getters.waterTestHistory].reverse()
    }
  },
  methods: {
    handleClick(data) {
      if (data) {
        this.description.data = data
        this.description.dialog = true
      }
    }
  }
}
</script>
