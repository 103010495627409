var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"max-width":"500"},model:{value:(_vm.edit.dialog),callback:function ($$v) {_vm.$set(_vm.edit, "dialog", $$v)},expression:"edit.dialog"}},[_c('v-card',{staticClass:"mx-auto"},[_c('v-card-title',[_vm._v("Edit "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.edit.dialog = !_vm.edit.dialog}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-container',[_c('base-form',{attrs:{"data":_vm.edit.data},on:{"submit":_vm.updateWaterTestHistory,"cancel":function($event){_vm.edit.dialog = false}},scopedSlots:_vm._u([{key:"button",fn:function(){return [_vm._v(" Update "),_c('v-icon',[_vm._v("mdi-content-save")])]},proxy:true}])})],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"400"},model:{value:(_vm.dlt.dialog),callback:function ($$v) {_vm.$set(_vm.dlt, "dialog", $$v)},expression:"dlt.dialog"}},[_c('v-card',{staticClass:"mx-auto"},[_c('v-card-title'),_c('v-card-text',[_vm._v(" Are you sure? Do you want to delete this item? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){return _vm.deleteItem()}}},[_vm._v("Yes")]),_c('v-btn',{attrs:{"color":"secondary","text":""},on:{"click":function($event){_vm.dlt.dialog = false}}},[_vm._v("No")]),_c('v-spacer')],1)],1)],1),_c('base-data-table',{staticClass:"elevation-1",attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.waterTestHistory,"dense":""},scopedSlots:_vm._u([{key:"item.nitrate",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(Number(item.nitrate).toFixed(1)))])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(typeof item.report == 'object' && item.report.url)?_c('v-tooltip',{staticClass:"d-inline",attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"},on:{"click":function($event){return _vm.newWindow(item.report.url)}}},'v-icon',attrs,false),on),[_vm._t("icon",[_vm._v("mdi-file-pdf")])],2)]}}],null,true)},[_c('span',[_vm._t("tooltip",[_vm._v(_vm._s(item.report.filename))])],2)]):_vm._e(),_c('v-tooltip',{staticClass:"d-inline",attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"},on:{"click":function($event){return _vm.editItem(item.index)}}},'v-icon',attrs,false),on),[_vm._t("icon",[_vm._v("mdi-pencil")])],2)]}}],null,true)},[_c('span',[_vm._t("tooltip",[_vm._v("Edit")])],2)]),_c('v-tooltip',{staticClass:"d-inline",attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"error"},on:{"click":function($event){return _vm.showDeleteDialog(item.index)}}},'v-icon',attrs,false),on),[_vm._t("icon",[_vm._v("mdi-trash-can")])],2)]}}],null,true)},[_c('span',[_vm._t("tooltip",[_vm._v("Delete")])],2)])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }