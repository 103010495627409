<template>
  <v-main>
    <v-container>
      <v-dialog v-model="dialogs.source" max-width="600"
        ><v-card class="mx-auto">
          <v-card-title>
            Drinking Water Information <v-spacer></v-spacer>
            <v-btn icon @click="closeSourceCard()"
              ><v-icon>mdi-close</v-icon></v-btn
            >
          </v-card-title>
          <v-container class="pa-3">
            <source-form
              @cancel="closeSourceCard()"
              @submit="saveSourceInformation"
              :data="sourceInformationData"
            ></source-form> </v-container></v-card
      ></v-dialog>
      <v-dialog v-model="dialogs.barnstableCountyTest" max-width="700">
        <v-card class="mx-auto">
          <v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="dialogs.barnstableCountyTest = false"
              ><v-icon>mdi-close</v-icon></v-btn
            >
          </v-card-title>
          <v-container class="dialog-water-test">
            <div>
              <div class="water-test-content-1">
                For Your Health's Sake, Get Your Water Tested!
              </div>
              <div class="water-test-content-2">
                Call or Text
                <phone-number phone="508-296-0007"></phone-number>
                and Request a Free Test
              </div>
            </div>
            <div class="mt-9">
              Or Use Barnstable County Water Test Kits ($55)<br />
              <a
                v-if="checkParcelTown('Truro', this.latestParcel)"
                class="decoration-none link--water-test"
                href="https://www.truro-ma.gov/health-department/pages/water-testing-for-private-wells"
                >www.truro-ma.gov/health-department/pages/water-testing-for-private-wells</a
              >
            </div>
          </v-container>
          <v-card-text></v-card-text>
        </v-card>
      </v-dialog>

      <feature-breadcrumb :feature="fBreadcrumb">
        <template>
          <div>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <span v-on="on">
                  {{ fBreadcrumb.text }}
                  <water-source-chip
                    :source="
                      sourceInformationData
                        ? sourceInformationData.water_source
                        : 'Specify water source'
                    "
                  ></water-source-chip>
                </span>
              </template>
              <span>Water Source</span>
            </v-tooltip>
          </div>
        </template>
        <template #action>
          <ParcelGalleryButton
            v-model="gallery"
            class="ml-1"
            icon
            :count="galleryCount"
          />
        </template>
      </feature-breadcrumb>

      <v-alert
        max-width="400"
        class="mx-auto"
        v-model="saved"
        text
        type="success"
        >Data saved</v-alert
      >
      <v-row>
        <v-col cols="12" sm="5">
          <FeatureGallery
            feature="Water"
            v-model="gallery"
            :count.sync="galleryCount"
          />
          <v-card class="mx-auto" elevation="3">
            <v-card-title
              class="primary--text cursor-pointer d-sm-none d-flex"
              @click="dialogs.addData = !dialogs.addData"
              >Add Water Test
              <v-btn icon>
                <v-icon class="primary--text">{{
                  dialogs.addData ? 'mdi-chevron-up' : 'mdi-chevron-down'
                }}</v-icon></v-btn
              ></v-card-title
            >

            <v-container v-if="dialogs.addData">
              <div class="ml-5 mr-5 mt-2 mb-2">
                <water-add-form @updated="saveData"></water-add-form>
              </div>
            </v-container>
          </v-card>
          <div
            v-if="latestParcel && latestParcel.townCity == 'Truro'"
            class="text-center mt-3"
          >
            <v-btn
              class="text-capitalize"
              outlined
              color="success"
              @click="
                dialogs.barnstableCountyTest = !dialogs.barnstableCountyTest
              "
              >How to Get your Water Tested</v-btn
            >
          </div>
        </v-col>
        <v-col cols="12" sm="7">
          <water-test-chart></water-test-chart>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <water-history
            :loading="loading"
            @updated="saveData()"
          ></water-history>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import WaterAddForm from '@/components/Water/AddForm'
import WaterHistory from '@/components/Water/WaterHistory'
import WaterTestChart from '@/components/Water/Chart'
import water from '@/mixins/water'
import parcel from '@/mixins/parcel'
import SourceForm from '@/components/Water/SourceForm'
import { mapGetters } from 'vuex'
export default {
  metaInfo: {
    title: 'Water'
  },
  components: {
    WaterAddForm,
    WaterHistory,
    WaterTestChart,
    SourceForm
  },
  mixins: [water, parcel],
  data: () => ({
    gallery: false,
    galleryCount: 0,
    fBreadcrumb: {
      text: 'Water',
      to: '?source=true'
    },
    dialogs: {
      addData: true,
      source: false,
      barnstableCountyTest: false
    },
    loading: true,
    loadings: {
      save: false,
      clear: false
    },
    saved: false,
    saving: false
  }),
  computed: {
    xs() {
      return this.$vuetify.breakpoint.xs
    },
    url() {
      return this.$route.query.source
    },
    sourceDialog() {
      return this.dialogs.source
    },
    sourceInformationData() {
      return this.$store.getters.waterSource
    },
    ...mapGetters(['latestParcel'])
  },
  async mounted() {
    let waterData = await this.getWaterTestHistory(this.$route.params.id)
    if (waterData && waterData.data.length == 0) {
      this.dialogs.addData = true
    } else {
      if (this.xs) {
        this.dialogs.addData = false
      }
    }
    if (waterData) {
      this.$store.commit('setWaterTestHistory', waterData.data)
    }
    if (waterData && waterData.source) {
      this.$store.commit('setWaterSource', waterData.source)
    }

    this.loading = false

    this.checkWaterSource()
  },
  destroyed() {
    this.$store.commit('setWaterTestHistory', [])
    this.$store.commit('setWaterSource', null)
  },
  methods: {
    async saveData() {
      this.saving = true
      await this.saveWaterTestHistory(
        this.$route.params.id,
        this.$store.getters.waterTestHistory
      )
      this.saving = false
    },
    checkWaterSource() {
      const checked = this.$route.query.checked;
      if (checked) {
        return
      }
      if (!this.$store.getters.waterSource) {
        let newUrl = this.$route.path + '?source=true'
        if (this.$route.fullPath != newUrl) {
          this.$router.push(newUrl)
        }
        this.dialogs.source = true
      }
      if (this.url) {
        this.dialogs.source = true
      }
    },
    showSourceCard() {
      this.dialogs.source = true
    },
    closeSourceCard() {
      this.dialogs.source = false
    },
    updateSourceCard() {
      if (this.url) {
        this.dialogs.source = true
      } else {
        this.dialogs.source = false
      }
    },
    saveSourceInformation(data) {
      this.$store.commit('setWaterSource', data)
      let waterData = {
        data: this.$store.getters.waterTestHistory,
        source: this.$store.getters.waterSource
      }
      this.saveWaterData(this.$route.params.id, waterData)
      this.dialogs.source = false
    }
  },
  watch: {
    xs() {
      if (!this.xs) {
        this.dialogs.addData = true
      }
    },
    url() {
      this.updateSourceCard()
    },
    sourceDialog() {
      if (this.dialogs.source) {
        let newUrl = this.$route.path + '?source=true'
        if (this.$route.fullPath != newUrl) {
          this.$router.push(newUrl)
        }
      } else {
        if (this.$route.fullPath != this.$route.path) {
          this.$router.push(this.$route.path + '?checked=true')
        }
      }
    }
  }
}
</script>

<style scoped>
@media screen and (min-width: 600px) {
  .address {
    margin-left: 5rem;
  }
}
.address {
  color: rgb(89, 92, 95);
}
.dialog-water-test {
  padding-top: 10px;
  text-align: center;
  padding-bottom: 1rem;
  font-size: 1.5rem;
}
.link--water-test {
  font-size: 1.1rem;
}
@media (max-width: 600px) {
  .dialog-water-test {
    font-size: 1rem;
  }
  .link--water-test {
    font-size: 0.7rem;
  }
}

.water-test-content-1 {
  /* font-size: 1.5rem; */
  color: #2d6fba;
}
.water-test-content-2 {
  /* font-size: 1.5rem; */
  /* margin-top: -1.5rem; */
  color: #e93223;
}
</style>
