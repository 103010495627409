<template>
  <div>
    <v-dialog v-model="edit.dialog" max-width="500">
      <v-card class="mx-auto">
        <v-card-title
          >Edit <v-spacer></v-spacer>
          <v-btn icon @click="edit.dialog = !edit.dialog"
            ><v-icon>mdi-close</v-icon></v-btn
          ></v-card-title
        >
        <v-container>
          <base-form
            :data="edit.data"
            @submit="updateWaterTestHistory"
            @cancel="edit.dialog = false"
          >
            <template v-slot:button>
              Update <v-icon>mdi-content-save</v-icon></template
            >
          </base-form>
        </v-container>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dlt.dialog" max-width="400">
      <v-card class="mx-auto">
        <v-card-title></v-card-title>
        <v-card-text>
          Are you sure? Do you want to delete this item?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="deleteItem()">Yes</v-btn
          ><v-btn color="secondary" text @click="dlt.dialog = false">No</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <base-data-table
      :loading="loading"
      :headers="headers"
      :items="waterTestHistory"
      class="elevation-1"
      dense
    >
      <template v-slot:[`item.nitrate`]="{ item }">
        <span>{{ Number(item.nitrate).toFixed(1) }}</span>
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <v-tooltip
          v-if="typeof item.report == 'object' && item.report.url"
          bottom
          class="d-inline"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              color="primary"
              @click="newWindow(item.report.url)"
              ><slot name="icon">mdi-file-pdf</slot></v-icon
            >
          </template>
          <span
            ><slot name="tooltip">{{ item.report.filename }}</slot></span
          >
        </v-tooltip>
        <v-tooltip bottom class="d-inline">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              color="primary"
              @click="editItem(item.index)"
              ><slot name="icon">mdi-pencil</slot></v-icon
            >
          </template>
          <span><slot name="tooltip">Edit</slot></span>
        </v-tooltip>
        <v-tooltip bottom class="d-inline">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              color="error"
              @click="showDeleteDialog(item.index)"
              ><slot name="icon">mdi-trash-can</slot></v-icon
            >
          </template>
          <span><slot name="tooltip">Delete</slot></span>
        </v-tooltip>
      </template>
    </base-data-table>
  </div>
</template>

<script>
import utils from '@/mixins/utils'
import BaseForm from '@/components/Water/BaseForm'
import parcel from '@/mixins/parcel'
import { mapGetters, mapMutations } from 'vuex'
export default {
  mixins: [utils, parcel],
  components: {
    BaseForm: BaseForm
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    headers: [
      {
        text: 'Date',
        align: 'center',
        sortable: false,
        value: 'testDate',
        class: 'primary white--text'
      },
      {
        text: 'Nitrate mg/L',
        align: 'center',
        sortable: false,
        value: 'nitrate',
        class: 'primary white--text'
      },
      {
        text: 'Lab',
        align: 'center',
        sortable: false,
        value: 'lab',
        class: 'primary white--text'
      },
      {
        text: 'Remarks',
        align: 'center',
        sortable: false,
        value: 'remarks',
        class: 'primary white--text'
      },
      {
        text: 'Actions',
        align: 'center',
        sortable: false,
        value: 'action',
        class: 'primary white--text',
        width: 120
      }
    ],
    edit: {
      dialog: false,
      data: null
    },
    dlt: {
      dialog: false
    }
  }),
  computed: {
    isMD() {
      return this.$vuetify.breakpoint.mdAndDown
    },
    ...mapGetters(['activeWater', 'waterTestHistory', 'activeWaterData'])
  },
  methods: {
    editItem(index) {
      this.setActiveWater(index)
      this.edit.data = this.copyObject(this.activeWaterData)
      this.edit.dialog = true
    },
    showDeleteDialog(index) {
      this.setActiveWater(index)
      this.dlt.dialog = true
    },
    deleteItem() {
      if (this.activeWater != -1) {
        this.$store.commit('removeWaterTestHistory', this.activeWater)
        this.$emit('updated')
        this.setActiveWater(-1)
        this.dlt.dialog = false

        this.updateMetrics({ feature: 'water', action: 'delete' })
      }
    },
    updateWaterTestHistory(data) {
      this.$store.commit('updateWaterTestHistory', {
        data: this.copyObject(data.formData),
        index: this.activeWater
      })
      this.edit.dialog = false
      this.$emit('updated')

      this.updateMetrics({ feature: 'water', action: 'update' })
    },

    ...mapMutations(['setActiveWater'])
  }
}
</script>

<style></style>
